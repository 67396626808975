import { dateFormat } from "@/api/Tools"
import UserStorage from '../api/UserStorage';

// 处理服务端返回的日期 2024-05-29T14:28:32往后推1年
export function disposeServerDateFn2(str, format = "yyyy-MM-dd HH:mm:ss") {
    if(!str) return '-'
    let date = new Date(str)
    date.setFullYear(date.getFullYear() + 1) 
    return dateFormat(date, format)
}

// 处理服务端返回的日期 2024-05-29T14:28:32
export function disposeServerDateFn(str, format = "yyyy-MM-dd HH:mm:ss") {
    if(!str) return '-'
    return dateFormat(new Date(str), format)
}


// 数组转对应枚举
function mapListStatus(list, idField, colorField) {
  return list.reduce((result, item) => {
    result[item[idField]] = item[colorField];
    return result;
  }, {});
}

/**
 * 
 * @param {*} state 门店类别
 */
export const shopTypeList = [
  { id: 1, name: '蔓迪直营诊所', color: '#409EFF' },
  { id: 2, name: '毛囊监测站', color: '#67C23A' },
]

export function shopTypeFn(state, type = 'name') {
  const map = mapListStatus(shopTypeList, 'id', type);
  return map[state]
}

/**
 * 
 * @param {*} state 药品类别
 */
export const medicineTypeList = [
  { id: 1, name: '热门药品', color: '#409EFF' },
  { id: 2, name: '上药工具', color: '#67C23A' },
]

export function medicineTypeFn(state, type = 'name') {
  const map = mapListStatus(medicineTypeList, 'id', type);
  return map[state]
}


/**
 * 
 * @param {*} state 药品类别
 */
export const medicineUrlTypeList = [
  { id: 0, name: '内部小程序', color: '#F56C6C', hint: '路径格式为：pages/tabBar/community/community' },
  { id: 1, name: '外部小程序', color: '#409EFF', hint: '请在对应小程序的微信公众平台上查询appId，互联网医院appId：wx8ad3e7b2d48c93e9' },
  { id: 2, name: 'H5', color: '#67C23A', hint: '路径格式为：https://www.baidu.com/' },
]

export function medicineUrlTypeFn(state, type = 'name') {
  const map = mapListStatus(medicineUrlTypeList, 'id', type);
  return map[state]
}

/**
 * 
 * @param {*} state 脱发等级
 */
export const alopeciaLevelList = [
  { id: 1, name: '一级', color: '#409EFF' },
  { id: 2, name: '二级', color: '#67C23A' },
  { id: 3, name: '三级', color: '#67C23A' },
  { id: 4, name: '四级', color: '#67C23A' },
  { id: 5, name: '五级', color: '#67C23A' },
  { id: 6, name: '六级', color: '#67C23A' },
  { id: 7, name: '七级', color: '#67C23A' },
]

export function alopeciaLevelFn(state, type = 'name') {
  const map = mapListStatus(alopeciaLevelList, 'id', type);
  return map[state]
}

export const womanAlopeciaLevelList = [
  { id: 1, name: '一级', color: '#409EFF' },
  { id: 2, name: '二级', color: '#67C23A' },
  { id: 3, name: '三级', color: '#67C23A' },
]

/**
 * 
 * @param {*} state 性别
 */
export const genderList = [
  { id: 0, name: '男', color: '#409EFF' },
  { id: 1, name: '女', color: '#67C23A' },
  { id: 2, name: '未知', color: '#67C23A' },
]

export function genderFn(state, type = 'name') {
  const map = mapListStatus(genderList, 'id', type);
  return map[state]
}

/**
 * 
 * @param {*} state 性别
 */
export const genderAiList = [
  { id: 0, name: '男', color: '#409EFF' },
  { id: 1, name: '女', color: '#67C23A' },
  { id: 2, name: '未知', color: '#67C23A' },
 { id: 3, name: '男性测试集', color: '#67C23A' },
 { id: 4, name: '女性测试集', color: '#67C23A' }
 ]

export function genderAiFn(state, type = 'name') {
  const map = mapListStatus(genderAiList, 'id', type);
  return map[state]
}

export function phoneDisposeFn(phone) {
    if(!phone) return ''
    const isViewMobile = UserStorage.getUserInfo('isViewMobile')
    if(isViewMobile == 1) return phone
    phone = "" + phone
    let tel1 = phone.substr(0,3) + "***" + phone.substr(7)
    return tel1
}


/**
 * 
 * @param {*} state 客户行为统计
 */
export const behaviorStatisticsList = [
  { id: 2, name: '发帖数' },
  { id: 3, name: '评论数' },
  { id: 4, name: '分享笔记' },
  // { id: 5, name: '提问问题次数' },
  // { id: 6, name: '回答问题次数' },
  // { id: 7, name: '查看问题次数' },
  { id: 8, name: '查看活动详情' },
  { id: 9, name: '查看主题活动中奖名单' },
  // { id: 10, name: '查看会员活动的次数' },
  // { id: 11, name: '会员活动报名次数' },
  { id: 12, name: '参与主题活动次数' },
  // { id: 13, name: '消息查看次数' },
  { id: 14, name: '社区banner' },
  { id: 15, name: '使用指导banner' },
  { id: 16, name: '主题活动弹窗（发友分享弹窗）' },
  { id: 17, name: '药师守护banner' },
  { id: 18, name: '医生问诊banner' },
  // { id: 19, name: '首页拉新Banner' },
  { id: 20, name: '问诊券兑换成功次数' },
  { id: 21, name: '查看医生主页' },
  { id: 22, name: '查看医生简介' },
  { id: 24, name: '查看视频问诊医生列表' },
  { id: 25, name: '查看图文问诊医生列表' },
  { id: 26, name: '查看医生号源' },
  { id: 27, name: '查看我的问诊' },
  { id: 28, name: '查看药品订单' },
  { id: 29, name: '查看我的处方' },
  { id: 30, name: '预约问诊次数' },
  { id: 31, name: '发布患者评价' },
  { id: 32, name: '添加就诊人' },
  { id: 33, name: '咨询药师（按钮）' },
  { id: 34, name: '毛囊检测站的点击数' },
  { id: 35, name: '切换门店' },
  { id: 36, name: '预约检测' },
  // { id: 37, name: '查看蔓迪专家指导视频' },
  // // { id: 38, name: '打卡次数（累计）' },// 不计入打卡次数
  { id: 40, name: '查看焕新期的点击次数' },
  // { id: 41, name: '查看生发相册次数' },
  { id: 42, name: '修改昵称次数' },
  { id: 43, name: '修改手机号的次数' },
  { id: 44, name: '修改脱发等级次数' },
  { id: 45, name: '我的卡券查看次数' },
]

export function behaviorStatisticsFn(state, type = 'name') {
  const map = mapListStatus(behaviorStatisticsList, 'id', type);
  return map[state]
}


/**
 * 
 * @param {*} state AI检测结果
 */
export const identificationRecordManAIList = [
  { id: 1, name: '轻度脱发', color: '#79C98D' },
  { id: 2, name: '轻度脱发', color: '#79C98D' },
  { id: 3, name: '轻度脱发', color: '#79C98D' },
  { id: 4, name: '中度脱发', color: '#EDC75D' },
  { id: 5, name: '中度脱发', color: '#EDC75D' },
  { id: 6, name: '重度脱发', color: '#DA675A' },
  { id: 7, name: '重度脱发', color: '#DA675A' },
]

export const identificationRecordWomanAIList = [
  { id: 1, name: '轻度脱发', color: '#79C98D' },
  { id: 2, name: '中度脱发', color: '#EDC75D' },
  { id: 3, name: '重度脱发', color: '#DA675A' },
]

export function identificationRecordAIFn(state, type = 'name', gender) {
  const map = mapListStatus(gender == 1 ? identificationRecordWomanAIList : identificationRecordManAIList, 'id', type);
  return map[state]
}


/**
 * 
 * @param {*} state 渠道/店铺
 */
export const channelList = [
  { id: 'TAOBAO', name: '淘宝', shopName: '蔓迪官方旗舰店', color: '#FF5000' },
  { id: 'JOS', name: '京东', shopName: '蔓迪官方旗舰店', color: '#E2211C' },
  { id: 'DOUYIN', name: '抖音', shopName: '蔓迪京东自营官方旗舰店', color: '#111111' },
  { id: 'REDBOOK', name: '小红书', shopName: '蔓迪旗舰店', color: '#FF2442' },
]

export function channelFn(state, type = 'name') {
  const map = mapListStatus(channelList, 'id', type);
  return map[state]
}

/**
 * 
 * @param {*} state 交易状态
 */
export const orderStatusList = [
  { id: 'WAIT_BUYER_PAY', name: '下单未支付（待付款）' },
  { id: 'WAIT_SELLER_SEND_GOODS', name: '已付款未发货' },
  { id: 'SELLER_CONSIGNED_PART', name: '淘宝' },
  { id: 'WAIT_BUYER_CONFIRM_PAY', name: '已付款，卖家部分发货' },
  { id: 'TRADE_BUYER_SIGNED', name: '买家已签收（用于货到付款）' },
  { id: 'WAIT_BUYER_CONFIRM_GOODS', name: '已发货未确认（待收货）' },
  { id: 'TRADE_FINISHED', name: '交易成功 （由买家确认收货或系统自动触发）' },
  { id: 'TRADE_CLOSED', name: '交易关闭（付款以前，卖家或买家主动关闭交易，包括平台自动关闭情况）' },
  { id: 'PAID_FORBID_CONSIGN', name: '该状态代表订单已付款但是处于禁止发货状态（例如线上下单线下门店发货）' },
  { id: 'TRADE_CLOSED_ALL_REFUND', name: '全额退款交易关闭' },
  { id: 'TRADE_PAID', name: '订单已支付 ，该状态仅代表当前订单已支付成功，表示瞬时状态，稍后会自动修改成后面的状态。如果不关心此状态请再次请求详情接口获取下一个状态， 仅有赞平台' },
]

export function orderStatusFn(state, type = 'name') {
  const map = mapListStatus(orderStatusList, 'id', type);
  return map[state]
}


/**
 * 
 * @param {*} state 脱发等级
 */
export const alopeciaGradeList = [
  { id: 1, name: '一级' },
  { id: 2, name: '二级' },
  { id: 3, name: '三级' },
  { id: 4, name: '四级' },
  { id: 5, name: '五级' },
  { id: 6, name: '六级' },
]

export function alopeciaGradeFn(state, type = 'name') {
  const map = mapListStatus(alopeciaGradeList, 'id', type);
  return map[state]
}


/**
 * 
 * @param {*} state 治疗方案按钮
 */
export const schemeButtonList = [
  { id: 1, name: '普通按钮', color: '#409EFF' },
  { id: 2, name: '去咨询', color: '#67C23A' },
  { id: 3, name: '去记录', color: '#EDC75D' },
]

export function schemeButtonFn(state, type = 'name') {
  const map = mapListStatus(schemeButtonList, 'id', type);
  return map[state]
}